





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { IUploadFile } from '@/components/Ui/Uploader/interfaces';
import FileUploadIcon from '@/assets/icons/file-text.svg';
import { commonImageFileTypes } from './Uploader.vue';

@Component({
  components: {FileUploadIcon }
})
export default class UploaderItem extends Vue {
  @Prop({type: Object, required: true})
  private item!: IUploadFile;

  private get tag() {
    return this.item.url ? 'a' : 'div';
  }

  private get attrs() {
    return {
      ...(
        this.item.url
          ? {
              href: this.item.url,
              target: '_blank'
            }
          : []
      ),
    };
  }

  private get image() {
    return this.item.fileType && commonImageFileTypes.includes(this.item.fileType)
      ? this.item.url
      : null;
  }
}
