
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class UploaderInput extends Vue {
  @Prop({type: Boolean, required: true})
  private disabled!: boolean;

  public get uploader() {
    return this.$refs.uploader as HTMLInputElement;
  }
}
