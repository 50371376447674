




























import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import UploaderInput from './UploaderInput.vue';
import UploaderList from './UploaderList.vue';
import { IUploadFile } from '@/components/Ui/Uploader/interfaces';

export const commonImageFileTypes = [
  'APNG',
  'AVIF',
  'GIF',
  'JPEG',
  'PNG',
  'WebP'
];

const extensions = [
  ...commonImageFileTypes,
  'PDF'
]
  .map((item) => `.${item.toLowerCase()}`)
  .join(', ');

const defaultAttrs = () => ({
  type: 'file',
  name: 'file',
  accept: extensions,
  multiple: true,
});

const generateFileId = (file: File): string => file.name + file.size + file.type;

const dummyFile = (file: File): IUploadFile => ({
  id: generateFileId(file),
  fileName: file.name,
  fileType: file.type,
  size: String(file.size),
  url: null,
  createdAt: new Date()
});

@Component({
  components: { UploaderInput, UploaderList }
})
export default class Uploader extends Vue {
  public kilobytes: number = 1024;
  public megabytes: number = 50000;
  public fileMaxSize: number = this.kilobytes * this.megabytes;
  public uploadQueue: number = 0;
  public fileList: File[] = [];
  public uploadedList: IUploadFile[] = [];
  public isButtonDisabled: boolean = false;

  /**
   * @attachments is already uploaded data
   */

  @Prop({type: Array, required: false, default: () => []})
  private attachments!: IUploadFile[];

  /**
   * {@uploadFn and @removeFn} are request functions which must return Promise with response
   */

  @Prop({type: Function, required: true})
  private uploadFn!: (file: File) => Promise<IUploadFile>;

  @Prop({type: Function, required: true})
  private removeFn!: (fileId: string) => Promise<any>;

  @Provide('removedUploadedList')
  private removedUploadedList: string[] = [];

  public get attrs(): object {
    return {
      ref: 'uploaderInput',
      ...defaultAttrs(),
      ...this.$attrs,
      disabled: this.isButtonDisabled
    };
  }

  private get mergedUploadedList(): IUploadFile[] {
    return [
      ...this.attachments,
      ...this.uploadedList
    ];
  }

  private get isUploadedListAvailable(): boolean {
    return !!this.mergedUploadedList.length;
  }

  private handleUploadFile(files): void {
    // eslint-disable-next-line no-unused-vars
    const {_length, ...rest} = files;
    this.fileList = Object.values(rest);
    this.launchUploadFile();
  }

  private launchUploadFile(): void {
    this.isButtonDisabled = !!(this.fileList!.length);
    const length = this.fileList!.length - 1;
    this.addDummyFileToUploadedList();
    this.uploadFileByQueue(length);
  }

  private addDummyFileToUploadedList(): void {
    for (const file of this.fileList) {

      if (file && (file.size >= this.fileMaxSize)) {
        this.notify();
        continue;
      }

      this.addUploadedFileToUploadedList(dummyFile(file));
    }
  }

  private addUploadedFileToUploadedList(file: IUploadFile): void {
    this.uploadedList.push(file);
  }

  private removeFileFromUploadedList(fileId: string): void {
    this.uploadedList = this.uploadedList.filter((item) => item.id !== fileId);
  }

  private removeUploadedFileFromUploadedList(fileId: string) {
    this.menageRemovedUploadedList(fileId);
    return this.removeFn(fileId)
      .then(() => this.removeFileFromUploadedList(fileId))
      .finally(() => this.menageRemovedUploadedList(fileId));
  }

  private uploadFileByQueue(length: number) {
    const file = this.fileList && this.fileList.length && this.fileList[this.uploadQueue];

    if (file && (file.size >= this.fileMaxSize)) {
      return this.callback(length);
    }

    if (file && file.name) {
      return this.uploadFn(file)
        .then((item) => {
          this.removeFileFromUploadedList(generateFileId(file));
          this.addUploadedFileToUploadedList(item);
        })
        .finally(() => this.callback(length));
    }
  }

  private callback(length: number): void {
    if (this.uploadQueue < length) {
      this.uploadQueue += 1;
      this.uploadFileByQueue(length);
    } else {
      this.fileList = [];
      this.uploadQueue = 0;
      this.isButtonDisabled = false;
    }
  }

  private menageRemovedUploadedList(id: string) {
    if (this.removedUploadedList.includes(id)) {
      this.removedUploadedList.filter((item) => item !== id);
    } else {
      this.removedUploadedList.push(id);
    }
  }

  private notify() {
    this.$notify({
      group: 'notification',
      type: 'notification-error',
      text: this.$tc('uploader.uploadFileMaxSize'),
    });
  }
}
