










import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import UploaderItem from './UploaderItem.vue';
import { IUploadFile } from '@/components/Ui/Uploader/interfaces';

const comparator = (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();

@Component({
  components: { UploaderItem }
})
export default class UploaderList extends Vue {
  @Prop({type: Array, required: true})
  private listItem!: IUploadFile[];

  @Inject('removedUploadedList')
  private removedUploadedList!: string[];

  private get sortedListItem() {
    return this.listItem.length
      ? this.listItem.sort(comparator)
      : [];
  }

  private handleRemoveGuard(id: string) {
    // click delay
    setTimeout(() => {
      return this.removedUploadedList.includes(id)
        ? false
        : this.$emit('remove', id);
    }, 100);
  }
}
